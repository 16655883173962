<template>
  <div>
    <el-container>
      <div v-if="userDeptType === 'all'" class="UserDeptTree">
        <tree-layout treeTitle="组织架构" :treeLoading="treeLoading" :tree-options="treeOptions"
                     @tree-node-click="treeNodeClickAll" @include-down="includeDownAll" style="margin-right:5px;">
        </tree-layout>
      </div>
      <div v-else>
        <CommonTree class="UserDeptTree" :treeData="treeData" :defaultProps="defaultProps" :searchTitle="searchTitle"
                    :treeTitle="$t(`cip.plat.sys.user.title.treeTitle`)" :isShowdig="false" :showCheckbox="false" :defaultCheckedKeys="defaultCheckedKeys"
                    :popover-show="false" @getNodeClick="getNodeClick" @include-down="includeDown" />
      </div>
      <el-container>
        <el-main>
          <head-layout :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')">
          </head-layout>
          <grid-head-layout ref="searchFrom" :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
                            :search-columns="searchColumns" v-model="searchForm" @grid-head-search="searchChange"
                            @grid-head-empty="searchReset">
          </grid-head-layout>
          <grid-layout ref="gridLayout" class="UserDeptCrud" :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
                       :data-total="page.total" @page-size-change="onLoad" @page-current-change="onLoad"
                       @page-refresh-change="onLoad" :page="page" @gird-handle-select-click="selectionChange"
                       :tableOptions="tableOptions" :tableData="tableData" :tableLoading="tableLoading"
                       :searchColumns="searchColumns" :gridRowBtn="gridRowBtn" @role-defBtn="roleDefBtn">
          </grid-layout>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
import {roleAllocationByList} from "@/api/system/user";
import {getDeptLazyTreeAll,getDeptTree} from "@/api/system/dept";
import TreeLayout from "@/views/components/layout/tree-layout";
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import CommonTree from "@/views/components/com_tree/index"
import { mapGetters } from "vuex";

export default {
  name: "user",
  components: {
    TreeLayout,
    HeadLayout,
    GridLayout,
    CommonTree,
  },
  props: {
    deptCategory: [],
    userDeptType: {
      type: String,
      default: ""
    },
    publicParams:{
      type:Object,
      default:{}
    },
    treeParams:{
      type:Object,
      default:{}
    }
  },
  data() {
    return {
      treeLoading: true,
      searchTitle: 'title',
      defaultProps: {
        label: "title",
        value: "key"
      },
      searchForm: {},
      gridRowBtn: [
        {
          label: this.$t('cip.cmn.btn.defBtn'),
          emit: "role-defBtn",
          type: "text",
          icon: ""
        }
      ],
      defaultCheckedKeys: [],
      tableData: [],
      tableLoading: true,
      search: {},
      selectionList: [],
      query: {},
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0
      },
      props: {
        label: "title",
        value: "key"
      },
      treeDeptId: '',
      treeData: [],
      tableOptions: {
        customRowAddBtn: false,
        menu: true,
        selection: false,
        column: [
          {
            label: "用户账号",
            prop: "account",
            align: 'left',
            width: 150,
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.realName"),
            prop: "realName",
            width: 150,
            align: 'center',
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.deptName"),
            prop: "deptName",
            align: 'left',
            overHidden: true,
          },
          {
            label: "用户类型",
            prop: "personnelType",
            type: "select",
            width: 200,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            align: 'left',
            dataType: 'string',
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=personnel_type"
          },
        ],
      },
    };
  },
  watch: {
    defaultCheckedKeys: {
      handler: function (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            document.querySelector(".UserDeptTree .el-tree-node__content").click();
          });
        }
      },
    },
  },
  computed: {
    searchColumns() {
      return [
        {
          label: "",
          prop: "account",
          span: 4,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.account"),
        },
        {
          label: "",
          prop: "realName",
          span: 4,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.realName"),
        },
        {
          label: "用户类型",
          prop: "personnelType",
          type: "select",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          dicData: [],
          dataType: 'string',
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=personnel_type",
          span: 4,
          placeholder: "请选择用户类型",
        },
      ]
    },
    treeOptions() {
      let that = this;
      return {
        defaultExpandAll: false,
        nodeKey: 'id',
        lazy: true,
        treeLoad: function (node, resolve) {
          that.treeLoading = true;
          const parentId = (node.level === 0) ? 0 : node.data.id;
          getDeptLazyTreeAll(parentId).then(res => {
            resolve(res.data.data.map(item => {
              that.treeLoading = false;
              return {
                ...item,
                leaf: !item.hasChildren
              }
            }))
          });
        },
        menu: false,
        addBtn: false,
        // size: 'mini',
        props: {
          labelText: this.$t("cip.plat.sys.user.field.title"),
          label: 'title',
          value: 'value',
          children: 'children'
        }
      }
    },
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    if (this.userDeptType != 'all') {
      if (this.deptCategory !== undefined || this.deptCategory) {
        this.getTreeData(this.deptCategory.join(','));
      } else {
        this.getTreeData('');
      }
    } else {
      this.onLoad(this.page)
    }
  },
  methods: {
    roleDefBtn(row) {
      this.$emit('select-data', row, this.treeDeptId, this.treeDeptName);
    },
    getNodeClick(data) {
      this.treeDeptId = data.id;
      this.treeDeptName = data.deptName;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    includeDown(e) {
      this.page.currentPage = 1;
      this.query.isInclude = e;
      this.onLoad(this.page);
    },
    getTreeData(deptCategory) {
      // selectChildren(deptCategory).then((res) => {
      //   this.treeData = res.data.data;
      //   this.$nextTick(() => {
      //     this.defaultCheckedKeys.push(this.treeData[0].id);
      //   })
      // })
      getDeptTree('','',this.userInfo.dept_id,this.treeParams).then((res) => {
        this.treeData = res.data.data;
        this.$nextTick(() => {
          this.defaultCheckedKeys.push(this.treeData[0].id);
        })
      })
    },
    includeDownAll(e) {
      this.page.currentPage = 1;
      this.query.isInclude = e;
      this.onLoad(this.page);
    },
    treeNodeClickAll(node) {
      this.treeDeptId = node.id;
      this.treeDeptName = node.title;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    searchReset(data) {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      if (params.account == '') {
        delete params.account
      }
      if (params.realName == '') {
        delete params.realName
      }

      if (params.userTypeName == '') {
        delete params.userTypeName
      }
      delete params.$userTypeName
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);

    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
    },
    onLoad(page, params = {}) {
      this.param = params;
      this.page = page;
      this.query.isEnabled = 0;
      this.tableLoading = true;
      roleAllocationByList(page.currentPage, page.pageSize, Object.assign(params,this.query), this.treeDeptId).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        this.$refs.gridLayout.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.el-scrollbar {
  height: 100%;
}

.box .el-scrollbar__wrap {
  overflow: scroll;
}

::v-deep .UserDeptTree .el-tree {
  height: 60vh !important;
  max-height: 60vh !important;
}

::v-deep .UserDeptCrud .avue-crud .el-table {
  height: 55vh !important;
  max-height: 55vh !important;
}

::v-deep .el-dialog {
  margin-bottom: 0px !important;
}
</style>
